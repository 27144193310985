.footer {
    &-info {
        padding: 55px 65px;
        background: $color_gradient_3;

        @media screen and #{$media_md} {
            padding: 50px 40px;
        }
        @media screen and #{$media_sm} {
            padding: 50px 20px;
        }

        &__logo {
            margin-bottom: 40px;
        }

        &__item {
            color: #fff;

            @media screen and (max-width: 1400px) {
                font-size: .84rem;
            }
            @media screen and #{$media_xl} {
                font-size: .9rem;
            }
            @media screen and #{$media_lg} {
                font-size: 1rem;
            }

            + .footer-info__item {
                margin-top: 10px;
            }

            > .icon {
                position: relative;
                top: 2px;
                width: 18px;
                margin-right: 10px;
                font-size: 1.2rem;
                text-align: center;
            }
        }
    }

    .map {
        width: 100%;
        height: 100%;
        min-height: 290px;
    }
}
