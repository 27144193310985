button {
    cursor: pointer;
}

/* Placeholders */
::placeholder,
.form-control::placeholder {
    font-family: $font_headings;
    font-size: .9rem;
    font-weight: 300;
    color: $color_gray_3;
    opacity: 1;
}

/* Remove outline from focused buttons */
button:focus {
    outline: 0;
}

/* From */
.form {
    .form-group {
        margin-bottom: 0;

        + .form-group {
            margin-top: 26px;
        }
    }
    &__control {
        height: 57px;
        padding: 18px 30px;
        border: 1px solid $color_gray_4;
        border-radius: 40px;
        box-sizing: none;
        font-family: $font_headings;
        font-weight: 300;
        transition: border-color .2s ease;

        &:hover,
        &:focus {
            box-shadow: none;
            border-color: $color_main_1;
        }
    }


    textarea,
    textarea.form-control {
        height: 176px;
        min-height: 57px;
        max-height: 176px;
        border-radius: 20px;
    }
    label {
        margin-bottom: 8px;
        font-family: $font_headings;
        font-size: .9rem;
        font-weight: 300;
        color: $color_gray_2;
    }
    select.form-control:not([size]):not([multiple]) {
        height: 34px;
    }
    select.form-control {
        color: $color_body;
        cursor: pointer;

        &:focus {
            cursor: pointer;
        }

        option {
            color: $color_body;
        }
    }
    @-moz-document url-prefix() {
        select.form-control option {
            color: inherit
        }
    }
    select.form-control[multiple] option {
        color: inherit
    }
    /* fix for select on iOs devices */
    select.form-control {
        background-color: #fff;
    }

    // Description under form-control
    &-control-descrtiption {
        font-size: 0.8rem;
        margin-top: 7px;
        color: $color_gray_4;

        a {
            text-decoration: none;
        }
    }
}
