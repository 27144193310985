.job {
    display: flex;

    &-title {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        color: $color_main_1;
        font-weight: 700;
    }

    &-item,
    &-item-auto {
        display: flex;
        flex-direction: column;

        > span {
            display: block;

            + span {
                margin-top: 7px;
            }
        }
    }

    &-item {
        width: 100%;
        font-size: .89rem;
        color: $color_gray_1;

        &-auto {
            width: auto;
            white-space: nowrap;
            font-size: .9rem;
            color: $color_gray_2;

            + .job-item {
                margin-left: 14px;
            }
        }
    }
}
