html {
    height: 100%;
}

body {
    position: relative;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: $bg_body;

    &::-webkit-scrollbar{
        height: $scrollbar-width;
    	width: $scrollbar-width;
    	background-color: $scrollbar-color-fon;
    }
    &::-webkit-scrollbar-thumb{
    	background-color: $scrollbar-color;
    }
}

// Icons
.icon {
    display: inline-block;
    text-decoration: none;
    outline: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// Main Content
.main {
    position: relative;
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    img {
        max-width: 100%;
    }
}

// Copyright
.copyright {
    padding: 60px;
    background-color: #333;
    font-size: .98rem;
    color: #fff;
    letter-spacing: .05em;
    text-align: center;
}

// Gallery
.gallery {
    .gallery-img {
        display: block;

        > img {
            width: 100%;
            height: 480px;
            object-fit: cover;
            border-radius: 10px;

            @media screen and #{$media_lg} {
                height: 380px;
            }
            @media screen and #{$media_md} {
                height: 280px;
            }
        }
    }
}

// Box Decorated
.box-decorated {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        height: 70px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &-bottom-white {
        &::after {
            background-image: url("../images/decorated-bottom-white.png");
        }
    }
    &-bottom-gray {
        &::after {
            background-image: url("../images/decorated-bottom-gray.png");
        }
    }
    &-top-white {
        &::after {
            top: -1px;
            bottom: auto;

            background-image: url("../images/decorated-top-white.png");
        }
    }
}

// Bg parallax
.bg-image-parallax {
    img {
        width: 100%;
        height: 100%;
        // stylelint-disable-next-line
        font-family: "object-fit: cover";
        object-fit: cover;
    }
}

// Bg Image
.bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}

// Content Boxes
.box {
    position: relative;
    padding: 140px 120px;

    @media screen and #{$media_xl} {
        padding: 120px 40px;
    }
    @media screen and #{$media_md} {
        padding: 120px 20px;
    }

    &-1 {
        padding: 140px 0px;
    }
}

// Image Block
.image-block {
    display: inline-block;
    position: relative;
    text-align: left;

    &-hover {
        position: absolute;
        display: block;
        padding-top: 20px;
        padding-left: 42px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: $color_main_1;
        font-size: 4rem;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: opacity .4s ease, color .2s ease;

        .icon {
            position: relative;
            z-index: 1;
            transform: scale(0.6);
            transition: transform .4s ease;
        }

        &::after {
            content: "";
            position: absolute;
            top: -60px;
            left: -60px;
            width: 240px;
            height: 240px;
            border-radius: 100%;
            box-shadow: inset 0 0 0 0 rgba($color_main_1, .8);
            transform: scale(2);
            transition: box-shadow .4s ease;
        }
    }
    &:hover .image-block-hover {
        color: #fff;
        opacity: 1;

        .icon {
            transform: scale(1);
        }

        &::after {
            box-shadow: inset 0 0 0 120px rgba($color_main_1, .8);
        }
    }
}
