.navbar {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    top: -150px;
    margin-bottom: -100px;
    min-height: 100px;
    padding: 0 80px;
    font-family: $font_headings;
    background-color: rgba(#fff, 0);
    transition: background-color .2s ease;
    z-index: 2;

    @media screen and #{$media_xl} {
        padding: 0 60px;
    }
    @media screen and #{$media_md} {
        padding: 0 20px;
    }

    &.is_stuck {
        top: 0;
        background-color: #fff;
        box-shadow: 0 1px 0 0 rgba(#000, .1);

        .navbar__item_logo {
            opacity: 1;
            visibility: inherit;
        }
        .navbar__item--link {
            font-size: .8rem;
            color: $color_gray_1;

            &:hover,
            &:focus,
            &.active {
                color: $color_main_1;
            }
        }
        .navbar-burger > span {
            background-color: $color_gray_1;
        }
    }

    &-burger {
        cursor: pointer;
        display: none;
        position: relative;
        right: 40px;
        width: 24px;
        visibility: hidden;

        @media screen and #{$media_lg} {
            display: inline-block;
            visibility: inherit;
        }
        @media screen and #{$media_md} {
            right: 20px;
        }

        > span {
            display: block;
            height: 2px;
            background-color: #fff;
            transition: background-color .2s ease;

            + span {
                margin-top: 5px;
            }
        }
        &:hover,
        &.active {
            > span {
                background-color: $color_main_1;
            }
        }
    }

    > [class*="container"] {
        display: flex;
        flex-wrap: nowrap;
    }

    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 0;

        &.text-right {
            justify-content: flex-end;
        }
        &.text-left {
            justify-content: flex-start;
        }
        &.text-center {
            justify-content: center;
        }

        &.clone {
            @media screen and #{$media_lg} {
                display: none;
                visibility: hidden;
            };
        }

        &.width-auto {
            width: auto;
            flex-wrap: nowrap;
        }
    }
    &__item {
        display: inline-block;
        margin-right: 20px;
        list-style: none;

        @media screen and (max-width: 1200px) {
            margin-right: 10px;
        }

        &:last-child {
            margin-right: 0;
        }

        &_logo {
            width: 220px;
            margin: 0;
            padding: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility .2s ease, opacity .2s ease;

            .navbar__item--link {
                padding: 18px 26px;

                > img {
                    max-width: 100%;
                }

                @media screen and #{$media_md} {
                    padding: 18px 0;
                }
            }
        }

        &--link {
            display: block;
            padding: 11px 18px;
            color: #fff;
            font-size: 1rem;
            font-weight: 700;
            white-space: nowrap;
            letter-spacing: .06em;
            text-transform: uppercase;
            transition: font-size .2s ease, color .3s ease;

            &:hover,
            &:focus {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

// Navbar Mobile
.navbar-mobile {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: #fff;
    z-index: 101;
    transform: translateX(100%);
    transition: transform .4s ease;

    &.active {
        transform: translateX(0);
    }


    .navbar-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar__item {
        margin-left: 0;

        &--link {
            color: $color_gray_1;
            text-transform: none;

            &:hover,
            &:focus,
            &.active {
                color: $color_main_1;
            }
        }
    }
}

// Navbar Close
.navbar-close {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color_main_1, .8);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease, visibility .2s ease;

    &.active {
        opacity: 1;
        visibility: inherit;
    }
}
