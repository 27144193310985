.loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color_main_1, .8);
    z-index: 1000;
    opacity: 1;
    visibility: inherit;
    transition: visibility .3s ease, opacity .3s ease;

    &.loaded {
        opacity: 0;
        visibility: hidden;
    }

    .spinner {
        position: relative;
        width: $spinner-size;
        height: $spinner-size;
        border-radius: 50%;
        box-shadow: inset 0 0 0 $spinner-weight $spinner-color;

        i {
            position: absolute;
            display: block;
            width: $spinner-size_inner;
            height: $spinner-size_inner;
            overflow: hidden;
            z-index: 1;
            transform-origin: $spinner-size / 2 $spinner-size / 2;
            animation: spinner $spinner-speed infinite linear;

            &::after {
                content: "";
                display: block;
                width: $spinner-size;
                height: $spinner-size;
                border-radius: 50%;
                box-shadow: inset 0 0 0 $spinner-weight $spinner-color_inner;
                z-index: 1;
            }
        }
    }
    @keyframes spinner {
        100% { transform: rotate(360deg) }
    }
}
