$helper-m: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 18, 20, 30, 40, 50, 60, 70, 80 !default;
@each $item in $helper-m {
    .m-#{$item} {
        margin: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mt-#{$item} {
        margin-top: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .ml-#{$item} {
        margin-left: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mr-#{$item} {
        margin-right: #{$item}px !important;
    }
}
@each $item in $helper-m {
    .mb-#{$item} {
        margin-bottom: #{$item}px !important;
    }
}
