.container {
    @media screen and #{$media_xxl} {
        max-width: 1110px;
    }
    @media screen and #{$media_sm} {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.vertical-gap {
    margin-top: -30px;

    @media screen and #{$media_sm} {
        margin-top: -20px;
    }

    > [class*="col"] {
        padding-top: 30px;

        @media screen and #{$media_sm} {
            padding-top: 20px;
        }
    }
}
.row.gup-20 {
    margin-right: -10px;
    margin-left: -10px;

    > [class*="col"] {
        padding-right: 10px;
        padding-left: 10px;
    }

    &.vertical-gap {
        margin-top: -20px;

        > [class*="col"] {
            padding-top: 20px;
        }
    }
}
