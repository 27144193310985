// Main btn
.btn {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 15px 65px;
    border: 0;
    border-radius: 60px;
    box-shadow: none;
    background: $color_gradient_2;
    font-family: $font_headings;
    font-size: .9rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
    color: #fff;
    letter-spacing: .1em;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;
    transition: opacity .3s ease;

    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
        opacity: .9;
    }
}

.btn-decorated {
    display: inline-block;
    margin: 0 25px;
    padding: 10px 43px;
    border-radius: 20px 20px 0 0;

    &:hover,
    &:focus {
        background-color: $color_main_1;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 25px;
        height: 20px;
        background-position: center;
        background-size: cover;
    }
    &::before {
        right: calc(100% - 1px);
        background-image: url(../images/btn-decorated-left.svg);
    }
    &::after {
        left: calc(100% - 1px);
        background-image: url(../images/btn-decorated-right.svg);
    }
}
