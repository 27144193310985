.social-icons {
    padding: 0;
    margin: 0;
    font-size: 0;
    list-style: none;

    > li {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        > * {
            cursor: pointer;
            display: block;
            width: 40px;
            height: 40px;
            font-size: 1.1rem;
            color: #fff;
            border-radius: 100%;
            line-height: 2em;
            text-decoration: none;
            text-align: center;
            transition: background-color .2s ease;

            > .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    @each $name, $color in $colors_social {
        > li > .social-icons__#{$name} {
            background-color: #454545;

            &:hover {
                background-color: $color;
            }
        }
    }
}
