html {
    font-size: 1.26rem;

    @media screen and (max-width: 1700px) {
        font-size: 1.17rem;
    }
    @media screen and (max-width: 1500px) {
        font-size: 1.1rem;
    }
    @media screen and #{$media_lg} {
        font-size: 1rem;
    }
    @media screen and #{$media_xs} {
        font-size: .94rem;
    }
    @media screen and #{$media_xxs} {
        font-size: .9rem;
    }
}

body {
    font-family: $font_body;
    color: $color_body;
    letter-spacing: .04em;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b, strong {
    font-weight: bold;
}

label {
    font-weight: bold;
    color: $color_main_1;
}

a {
    color: $color_main_1;
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
        text-decoration: underline;
        color: $color_main_1;
    }
    &:focus {
        text-decoration: none;
        outline: none;
        color: $color_main_1;
    }
}

p {
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }
}
ul {
    margin: 5px 0;
    padding-left: 20px;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 14px;
    font-family: $font_headings;
    font-weight: 900;
    color: $color_gray_1;
    letter-spacing: .04em;
}
h1,
.h1 {
    font-size: 2.6rem;

    @media screen and #{$media_sm} {
        font-size: 2.5rem;
    }
    @media screen and #{$media_xs} {
        font-size: 2.4rem;
    }
    @media screen and #{$media_xxs} {
        font-size: 2.3rem;
    }
}
h5,
.h5 {
    font-size: 1.3rem;
}

.title {
    margin-bottom: 32px;

    &-gradient {
        background: $color_gradient_1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $color_main_1;
    }
}
