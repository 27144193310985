.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 169, 30, 0.8);
  z-index: 1000;
  opacity: 1;
  visibility: inherit;
  transition: visibility .3s ease, opacity .3s ease;
}

.loader.loaded {
  opacity: 0;
  visibility: hidden;
}

.loader .spinner {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.3);
}

.loader .spinner i {
  position: absolute;
  display: block;
  width: 31.5px;
  height: 31.5px;
  overflow: hidden;
  z-index: 1;
  transform-origin: 45px 45px;
  animation: spinner 0.6s infinite linear;
}

.loader .spinner i::after {
  content: "";
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px #fff;
  z-index: 1;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

html {
  font-size: 1.26rem;
}

@media screen and (max-width: 1700px) {
  html {
    font-size: 1.17rem;
  }
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 991px) {
  html {
    font-size: 1rem;
  }
}

@media screen and (max-width: 420px) {
  html {
    font-size: .94rem;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: .9rem;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  color: #666;
  letter-spacing: .04em;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong {
  font-weight: bold;
}

label {
  font-weight: bold;
  color: #21a91e;
}

a {
  color: #21a91e;
  text-decoration: none;
  transition: color .2s ease;
}

a:hover {
  text-decoration: underline;
  color: #21a91e;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: #21a91e;
}

p {
  margin-bottom: 10px;
}

p:last-of-type {
  margin-bottom: 0;
}

ul {
  margin: 5px 0;
  padding-left: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #454545;
  letter-spacing: .04em;
}

h1,
.h1 {
  font-size: 2.6rem;
}

@media screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 420px) {
  h1,
  .h1 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 380px) {
  h1,
  .h1 {
    font-size: 2.3rem;
  }
}

h5,
.h5 {
  font-size: 1.3rem;
}

.title {
  margin-bottom: 32px;
}

.title-gradient {
  background: linear-gradient(to bottom, #fcee21 0%, #009245 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #21a91e;
}

html {
  height: 100%;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
}

body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: #fafafa;
}

body::-webkit-scrollbar-thumb {
  background-color: #21a91e;
}

.icon {
  display: inline-block;
  text-decoration: none;
  outline: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.main img {
  max-width: 100%;
}

.copyright {
  padding: 60px;
  background-color: #333;
  font-size: .98rem;
  color: #fff;
  letter-spacing: .05em;
  text-align: center;
}

.gallery .gallery-img {
  display: block;
}

.gallery .gallery-img > img {
  width: 100%;
  height: 480px;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width: 991px) {
  .gallery .gallery-img > img {
    height: 380px;
  }
}

@media screen and (max-width: 767px) {
  .gallery .gallery-img > img {
    height: 280px;
  }
}

.box-decorated {
  position: relative;
}

.box-decorated::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  height: 70px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.box-decorated-bottom-white::after {
  background-image: url("../images/decorated-bottom-white.png");
}

.box-decorated-bottom-gray::after {
  background-image: url("../images/decorated-bottom-gray.png");
}

.box-decorated-top-white::after {
  top: -1px;
  bottom: auto;
  background-image: url("../images/decorated-top-white.png");
}

.bg-image-parallax img {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
}

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.bg-image > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.box {
  position: relative;
  padding: 140px 120px;
}

@media screen and (max-width: 1199px) {
  .box {
    padding: 120px 40px;
  }
}

@media screen and (max-width: 767px) {
  .box {
    padding: 120px 20px;
  }
}

.box-1 {
  padding: 140px 0px;
}

.image-block {
  display: inline-block;
  position: relative;
  text-align: left;
}

.image-block-hover {
  position: absolute;
  display: block;
  padding-top: 20px;
  padding-left: 42px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #21a91e;
  font-size: 4rem;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s ease, color .2s ease;
}

.image-block-hover .icon {
  position: relative;
  z-index: 1;
  transform: scale(0.6);
  transition: transform .4s ease;
}

.image-block-hover::after {
  content: "";
  position: absolute;
  top: -60px;
  left: -60px;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0 rgba(33, 169, 30, 0.8);
  transform: scale(2);
  transition: box-shadow .4s ease;
}

.image-block:hover .image-block-hover {
  color: #fff;
  opacity: 1;
}

.image-block:hover .image-block-hover .icon {
  transform: scale(1);
}

.image-block:hover .image-block-hover::after {
  box-shadow: inset 0 0 0 120px rgba(33, 169, 30, 0.8);
}

.header {
  position: relative;
  padding: 94px 116px;
  min-height: 100vh;
}

@media screen and (max-width: 1400px) {
  .header {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .header-logo {
    width: 240px;
  }
}

.header::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.footer-info {
  padding: 55px 65px;
  background: linear-gradient(to left, #21a91e 0%, #009245 100%);
}

@media screen and (max-width: 767px) {
  .footer-info {
    padding: 50px 40px;
  }
}

@media screen and (max-width: 575px) {
  .footer-info {
    padding: 50px 20px;
  }
}

.footer-info__logo {
  margin-bottom: 40px;
}

.footer-info__item {
  color: #fff;
}

@media screen and (max-width: 1400px) {
  .footer-info__item {
    font-size: .84rem;
  }
}

@media screen and (max-width: 1199px) {
  .footer-info__item {
    font-size: .9rem;
  }
}

@media screen and (max-width: 991px) {
  .footer-info__item {
    font-size: 1rem;
  }
}

.footer-info__item + .footer-info__item {
  margin-top: 10px;
}

.footer-info__item > .icon {
  position: relative;
  top: 2px;
  width: 18px;
  margin-right: 10px;
  font-size: 1.2rem;
  text-align: center;
}

.footer .map {
  width: 100%;
  height: 100%;
  min-height: 290px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1110px;
  }
}

@media screen and (max-width: 575px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.vertical-gap {
  margin-top: -30px;
}

@media screen and (max-width: 575px) {
  .vertical-gap {
    margin-top: -20px;
  }
}

.vertical-gap > [class*="col"] {
  padding-top: 30px;
}

@media screen and (max-width: 575px) {
  .vertical-gap > [class*="col"] {
    padding-top: 20px;
  }
}

.row.gup-20 {
  margin-right: -10px;
  margin-left: -10px;
}

.row.gup-20 > [class*="col"] {
  padding-right: 10px;
  padding-left: 10px;
}

.row.gup-20.vertical-gap {
  margin-top: -20px;
}

.row.gup-20.vertical-gap > [class*="col"] {
  padding-top: 20px;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  top: -150px;
  margin-bottom: -100px;
  min-height: 100px;
  padding: 0 80px;
  font-family: "Montserrat", sans-serif;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color .2s ease;
  z-index: 2;
}

@media screen and (max-width: 1199px) {
  .navbar {
    padding: 0 60px;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 0 20px;
  }
}

.navbar.is_stuck {
  top: 0;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}

.navbar.is_stuck .navbar__item_logo {
  opacity: 1;
  visibility: inherit;
}

.navbar.is_stuck .navbar__item--link {
  font-size: .8rem;
  color: #454545;
}

.navbar.is_stuck .navbar__item--link:hover, .navbar.is_stuck .navbar__item--link:focus, .navbar.is_stuck .navbar__item--link.active {
  color: #21a91e;
}

.navbar.is_stuck .navbar-burger > span {
  background-color: #454545;
}

.navbar-burger {
  cursor: pointer;
  display: none;
  position: relative;
  right: 40px;
  width: 24px;
  visibility: hidden;
}

@media screen and (max-width: 991px) {
  .navbar-burger {
    display: inline-block;
    visibility: inherit;
  }
}

@media screen and (max-width: 767px) {
  .navbar-burger {
    right: 20px;
  }
}

.navbar-burger > span {
  display: block;
  height: 2px;
  background-color: #fff;
  transition: background-color .2s ease;
}

.navbar-burger > span + span {
  margin-top: 5px;
}

.navbar-burger:hover > span, .navbar-burger.active > span {
  background-color: #21a91e;
}

.navbar > [class*="container"] {
  display: flex;
  flex-wrap: nowrap;
}

.navbar-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.navbar-content.text-right {
  justify-content: flex-end;
}

.navbar-content.text-left {
  justify-content: flex-start;
}

.navbar-content.text-center {
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .navbar-content.clone {
    display: none;
    visibility: hidden;
  }
}

.navbar-content.width-auto {
  width: auto;
  flex-wrap: nowrap;
}

.navbar__item {
  display: inline-block;
  margin-right: 20px;
  list-style: none;
}

@media screen and (max-width: 1200px) {
  .navbar__item {
    margin-right: 10px;
  }
}

.navbar__item:last-child {
  margin-right: 0;
}

.navbar__item_logo {
  width: 220px;
  margin: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s ease, opacity .2s ease;
}

.navbar__item_logo .navbar__item--link {
  padding: 18px 26px;
}

.navbar__item_logo .navbar__item--link > img {
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .navbar__item_logo .navbar__item--link {
    padding: 18px 0;
  }
}

.navbar__item--link {
  display: block;
  padding: 11px 18px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: .06em;
  text-transform: uppercase;
  transition: font-size .2s ease, color .3s ease;
}

.navbar__item--link:hover, .navbar__item--link:focus {
  color: #fff;
  text-decoration: none;
}

.navbar-mobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  background-color: #fff;
  z-index: 101;
  transform: translateX(100%);
  transition: transform .4s ease;
}

.navbar-mobile.active {
  transform: translateX(0);
}

.navbar-mobile .navbar-content {
  flex-direction: column;
  align-items: flex-start;
}

.navbar-mobile .navbar__item {
  margin-left: 0;
}

.navbar-mobile .navbar__item--link {
  color: #454545;
  text-transform: none;
}

.navbar-mobile .navbar__item--link:hover, .navbar-mobile .navbar__item--link:focus, .navbar-mobile .navbar__item--link.active {
  color: #21a91e;
}

.navbar-close {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(33, 169, 30, 0.8);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;
}

.navbar-close.active {
  opacity: 1;
  visibility: inherit;
}

.social-icons {
  padding: 0;
  margin: 0;
  font-size: 0;
  list-style: none;
}

.social-icons > li {
  display: inline-block;
  margin-right: 10px;
}

.social-icons > li:last-child {
  margin-right: 0;
}

.social-icons > li > * {
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 100%;
  line-height: 2em;
  text-decoration: none;
  text-align: center;
  transition: background-color .2s ease;
}

.social-icons > li > * > .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.social-icons > li > *:hover, .social-icons > li > *:focus {
  text-decoration: none;
}

.social-icons > li > .social-icons__facebook {
  background-color: #454545;
}

.social-icons > li > .social-icons__facebook:hover {
  background-color: #475995;
}

.social-icons > li > .social-icons__googleplus {
  background-color: #454545;
}

.social-icons > li > .social-icons__googleplus:hover {
  background-color: #c14d3d;
}

.social-icons > li > .social-icons__instagram {
  background-color: #454545;
}

.social-icons > li > .social-icons__instagram:hover {
  background-color: #d12054;
}

.social-icons > li > .social-icons__odnoklassniki {
  background-color: #454545;
}

.social-icons > li > .social-icons__odnoklassniki:hover {
  background-color: #ee8208;
}

.social-icons > li > .social-icons__paypal {
  background-color: #454545;
}

.social-icons > li > .social-icons__paypal:hover {
  background-color: #1d3283;
}

.social-icons > li > .social-icons__skype {
  background-color: #454545;
}

.social-icons > li > .social-icons__skype:hover {
  background-color: #62aded;
}

.social-icons > li > .social-icons__tumblr {
  background-color: #454545;
}

.social-icons > li > .social-icons__tumblr:hover {
  background-color: #384961;
}

.social-icons > li > .social-icons__twitter {
  background-color: #454545;
}

.social-icons > li > .social-icons__twitter:hover {
  background-color: #6da9de;
}

.social-icons > li > .social-icons__vimeo {
  background-color: #454545;
}

.social-icons > li > .social-icons__vimeo:hover {
  background-color: #69b5e7;
}

.social-icons > li > .social-icons__vk {
  background-color: #454545;
}

.social-icons > li > .social-icons__vk:hover {
  background-color: #657da0;
}

.social-icons > li > .social-icons__youtube {
  background-color: #454545;
}

.social-icons > li > .social-icons__youtube:hover {
  background-color: #c6271e;
}

.social-icons > li > .social-icons__whatsapp {
  background-color: #454545;
}

.social-icons > li > .social-icons__whatsapp:hover {
  background-color: #25d366;
}

.assortment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px -10px 0;
  font-family: "Montserrat", sans-serif;
  border: 0;
}

.assortment .assortment-item {
  position: relative;
  display: flex;
  flex: 0 0 20%;
  width: 20%;
  padding: 20px 10px 0;
  background: none;
}

@media screen and (max-width: 1400px) {
  .assortment .assortment-item {
    flex: 0 0 25%;
    width: 25%;
  }
}

@media screen and (max-width: 991px) {
  .assortment .assortment-item {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 767px) {
  .assortment .assortment-item {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .assortment .assortment-item {
    flex: 0 0 80%;
    width: 80%;
  }
}

@media screen and (max-width: 420px) {
  .assortment .assortment-item {
    flex: 0 0 100%;
    width: 100%;
  }
}

.assortment .assortment-item > a:first-child {
  position: relative;
  display: block;
  width: 100%;
  padding: 1px 1px 96px 1px;
  margin: 0;
  overflow: hidden;
  border: 0;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  transition: box-shadow .3s ease;
}

@media screen and (max-width: 1700px) {
  .assortment .assortment-item > a:first-child {
    padding: 1px 1px 74px 1px;
  }
}

@media screen and (max-width: 1400px) {
  .assortment .assortment-item > a:first-child {
    padding: 1px 1px 96px 1px;
  }
}

@media screen and (max-width: 1199px) {
  .assortment .assortment-item > a:first-child {
    padding: 1px 1px 74px 1px;
  }
}

@media screen and (max-width: 991px) {
  .assortment .assortment-item > a:first-child {
    padding: 1px 1px 96px 1px;
  }
}

.assortment .assortment-item > a:first-child:hover, .assortment .assortment-item > a:first-child:focus {
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.1);
}

.assortment .assortment-item > a:first-child:hover .assortment-item__img img, .assortment .assortment-item > a:first-child:focus .assortment-item__img img {
  filter: brightness(110%);
}

.assortment .assortment-item > a:first-child::after {
  content: "";
  position: absolute;
  top: -100px;
  right: 0;
  left: 0;
  height: 300px;
  background: linear-gradient(to left, #21a91e 0%, #009245 100%);
  border-radius: 100%;
  opacity: 0;
  transition: transform .9s ease, opacity .2s ease;
  transform: scale(1);
  z-index: 0;
}

.assortment .assortment-item > a:first-child.active::after {
  opacity: 1;
  transform: scale(3);
}

.assortment .assortment-item > a:first-child.active .assortment-item__title {
  color: #fff;
}

.assortment .assortment-item > a:first-child.active + .assortment-price {
  color: rgba(255, 255, 255, 0.8);
}

.assortment .assortment-item > a:first-child.active + .assortment-price::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.assortment .assortment-item__img {
  position: relative;
  display: block;
  z-index: 1;
}

.assortment .assortment-item__img img {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
  border-radius: 9px 9px 0 0;
  filter: brightness(100%);
  transition: filter .3s ease;
}

.assortment .assortment-item__title {
  position: relative;
  display: block;
  width: calc(100% - 56px);
  margin: 20px auto 0 auto;
  font-size: .8rem;
  font-weight: 600;
  color: #666666;
  line-height: 1.2;
  letter-spacing: .03em;
  text-align: center;
  text-transform: uppercase;
  transition: color .3s ease;
  z-index: 1;
}

@media screen and (max-width: 1700px) {
  .assortment .assortment-item__title {
    width: calc(100% - 40px);
  }
}

.assortment-price {
  position: absolute;
  display: block;
  right: 10px;
  bottom: 20px;
  left: 10px;
  font-size: .7rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.35);
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

.assortment-price:hover, .assortment-price:focus {
  color: rgba(0, 0, 0, 0.35);
  text-decoration: none;
}

.assortment-price::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 65px;
  left: 65px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.assortment-price > .icon {
  position: relative;
  top: 3px;
  margin-right: 15px;
  font-size: 1.1rem;
}

.job {
  display: flex;
}

.job-title {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  color: #21a91e;
  font-weight: 700;
}

.job-item, .job-item-auto {
  display: flex;
  flex-direction: column;
}

.job-item > span, .job-item-auto > span {
  display: block;
}

.job-item > span + span, .job-item-auto > span + span {
  margin-top: 7px;
}

.job-item {
  width: 100%;
  font-size: .89rem;
  color: #454545;
}

.job-item-auto {
  width: auto;
  white-space: nowrap;
  font-size: .9rem;
  color: #666666;
}

.job-item-auto + .job-item {
  margin-left: 14px;
}

.btn {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 15px 65px;
  border: 0;
  border-radius: 60px;
  box-shadow: none;
  background: linear-gradient(to left, #fcee21 0%, #009245 100%);
  font-family: "Montserrat", sans-serif;
  font-size: .9rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
  letter-spacing: .1em;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 1;
  transition: opacity .3s ease;
}

.btn:hover, .btn:focus {
  color: #fff;
  box-shadow: none;
  opacity: .9;
}

.btn-decorated {
  display: inline-block;
  margin: 0 25px;
  padding: 10px 43px;
  border-radius: 20px 20px 0 0;
}

.btn-decorated:hover, .btn-decorated:focus {
  background-color: #21a91e;
}

.btn-decorated::before, .btn-decorated::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 25px;
  height: 20px;
  background-position: center;
  background-size: cover;
}

.btn-decorated::before {
  right: calc(100% - 1px);
  background-image: url(../images/btn-decorated-left.svg);
}

.btn-decorated::after {
  left: calc(100% - 1px);
  background-image: url(../images/btn-decorated-right.svg);
}

button {
  cursor: pointer;
}

/* Placeholders */
::placeholder,
.form-control::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: .9rem;
  font-weight: 300;
  color: #7a7a7a;
  opacity: 1;
}

/* Remove outline from focused buttons */
button:focus {
  outline: 0;
}

/* From */
.form {
  /* fix for select on iOs devices */
}

.form .form-group {
  margin-bottom: 0;
}

.form .form-group + .form-group {
  margin-top: 26px;
}

.form__control {
  height: 57px;
  padding: 18px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 40px;
  box-sizing: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  transition: border-color .2s ease;
}

.form__control:hover, .form__control:focus {
  box-shadow: none;
  border-color: #21a91e;
}

.form textarea,
.form textarea.form-control {
  height: 176px;
  min-height: 57px;
  max-height: 176px;
  border-radius: 20px;
}

.form label {
  margin-bottom: 8px;
  font-family: "Montserrat", sans-serif;
  font-size: .9rem;
  font-weight: 300;
  color: #666666;
}

.form select.form-control:not([size]):not([multiple]) {
  height: 34px;
}

.form select.form-control {
  color: #666;
  cursor: pointer;
}

.form select.form-control:focus {
  cursor: pointer;
}

.form select.form-control option {
  color: #666;
}

@-moz-document url-prefix() {
  .form select.form-control option {
    color: inherit;
  }
}

.form select.form-control[multiple] option {
  color: inherit;
}

.form select.form-control {
  background-color: #fff;
}

.form-control-descrtiption {
  font-size: 0.8rem;
  margin-top: 7px;
  color: #e5e5e5;
}

.form-control-descrtiption a {
  text-decoration: none;
}

.tab-content {
  position: relative;
}

.tab-content > .tab-pane {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
}

.tab-content > .active {
  position: relative;
  visibility: inherit;
}

.slider-swiper {
  padding-bottom: 32px;
}

.slider-swiper .swiper-pagination {
  bottom: 0;
}

.slider-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #21a91e;
  opacity: 1;
}
