.tab-content {
    position: relative;

    > .tab-pane {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
    }
    > .active {
        position: relative;
        visibility: inherit;
    }
}
