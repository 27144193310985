.assortment {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px -10px 0;
    font-family: $font_headings;
    border: 0;

    .assortment-item {
        position: relative;
        display: flex;
        flex: 0 0 20%;
        width: 20%;
        padding: 20px 10px 0;
        background: none;

        @media screen and (max-width: 1400px) {
            flex: 0 0 25%;
            width: 25%;
        }
        @media screen and #{$media_lg} {
            flex: 0 0 33.3333333333%;
            width: 33.3333333333%;
        }
        @media screen and #{$media_md} {
            flex: 0 0 50%;
            width: 50%;
        }
        @media screen and #{$media_sm} {
            flex: 0 0 80%;
            width: 80%;
        }
        @media screen and #{$media_xs} {
            flex: 0 0 100%;
            width: 100%;
        }

        > a:first-child {
            position: relative;
            display: block;
            width: 100%;
            padding: 1px 1px 96px 1px;
            margin: 0;
            overflow: hidden;
            border: 0;
            border-radius: 10px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, .08);
            background-color: #fff;
            transition: box-shadow .3s ease;

            @media screen and (max-width: 1700px) {
                padding: 1px 1px 74px 1px;
            }
            @media screen and (max-width: 1400px) {
                padding: 1px 1px 96px 1px;
            }
            @media screen and #{$media_xl} {
                padding: 1px 1px 74px 1px;
            }
            @media screen and #{$media_lg} {
                padding: 1px 1px 96px 1px;
            }

            &:hover,
            &:focus {
                box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, .1);

                .assortment-item__img img {
                    filter: brightness(110%);
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: -100px;
                right: 0;
                left: 0;
                height: 300px;
                background: $color_gradient_3;
                border-radius: 100%;
                opacity: 0;
                transition: transform .9s ease, opacity .2s ease;
                transform: scale(1);
                z-index: 0;
            }

            &.active {
                &::after {
                    opacity: 1;
                    transform: scale(3);
                }
                .assortment-item__title {
                    color: #fff;
                }
                + .assortment-price {
                    color: rgba(#fff, .8);

                    &::after {
                        background-color: rgba(#fff, .20);
                    }
                }
            }
        }

        &__img {
            position: relative;
            display: block;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                // stylelint-disable-next-line
                font-family: "object-fit: cover";
                object-fit: cover;
                border-radius: 9px 9px 0 0;
                filter: brightness(100%);
                transition: filter .3s ease;
            }
        }

        &__title {
            position: relative;
            display: block;
            width: calc(100% - 56px);
            margin: 20px auto 0 auto;
            font-size: .8rem;
            font-weight: 600;
            color: $color_gray_2;
            line-height: 1.2;
            letter-spacing: .03em;
            text-align: center;
            text-transform: uppercase;
            transition: color .3s ease;
            z-index: 1;

            @media screen and (max-width: 1700px) {
                width: calc(100% - 40px);
            }
        }
    }
    &-price {
        position: absolute;
        display: block;
        right: 10px;
        bottom: 20px;
        left: 10px;
        font-size: .7rem;
        font-weight: 500;
        color: rgba(#000, .35);
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
        z-index: 1;

        &:hover,
        &:focus {
            color: rgba(#000, .35);
            text-decoration: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: -10px;
            right: 65px;
            left: 65px;
            height: 1px;
            background-color: rgba(#000, .10);
        }

        > .icon {
            position: relative;
            top: 3px;
            margin-right: 15px;
            font-size: 1.1rem;
        }
    }
}
