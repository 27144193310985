// Main Color
$color_main_1: #21a91e !default;
$color_main_2: #21a91e !default;
$color_main_3: #21a91e !default;
$color_body: #666 !default;
$bg_body: #fff !default;

// Color Gradient
$color_gradient_1: linear-gradient(to bottom, #fcee21 0%, #009245 100%) !default;
$color_gradient_2: linear-gradient(to left, #fcee21 0%, #009245 100%) !default;
$color_gradient_3: linear-gradient(to left, #21a91e 0%, #009245 100%) !default;

// Color Gray
$color_gray_1: #454545 !default;
$color_gray_2: #666666 !default;
$color_gray_3: #7a7a7a !default;
$color_gray_4: #e5e5e5 !default;

// Spinner
$spinner-color: rgba(#fff, .3);
$spinner-color_inner: #fff;
$spinner-size: 90px;
$spinner-size_inner: $spinner-size * 0.35;
$spinner-weight: 4px;
$spinner-speed: .6s;

// Scrollbar
$scrollbar-width: 10px;
$scrollbar-color: $color_main_1;
$scrollbar-color-fon: #fafafa;

// Colors Social
$colors_social: (
    "facebook": #475995,
    "googleplus": #c14d3d,
    "instagram": #d12054,
    "odnoklassniki": #ee8208,
    "paypal": #1d3283,
    "skype": #62aded,
    "tumblr": #384961,
    "twitter": #6da9de,
    "vimeo": #69b5e7,
    "vk": #657da0,
    "youtube": #c6271e,
    "whatsapp": #25d366
) !default;

// Fonts
$font_body: 'Open Sans', sans-serif;
$font_headings: 'Montserrat', sans-serif;

// media
$media_xxl: "(min-width: 1200px)" !default;
$media_xl: "(max-width: 1199px)" !default;
$media_lg: "(max-width: 991px)" !default;
$media_md: "(max-width: 767px)" !default;
$media_sm: "(max-width: 575px)" !default;
$media_xs: "(max-width: 420px)" !default;
$media_xxs: "(max-width: 380px)" !default;
