.slider-swiper {
    padding-bottom: 32px;

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background-color: $color_main_1;
            opacity: 1;
        }
    }
}
