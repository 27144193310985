.header {
    position: relative;
    padding: 94px 116px;
    min-height: 100vh;

    @media screen and (max-width: 1400px) {
        padding: 60px;
    }
    @media screen and #{$media_md} {
        padding: 40px;
    }

    &-logo {
        @media screen and (max-width: 1200px) {
            width: 240px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 200px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
    }
}
